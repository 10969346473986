export default {
  install(Vue) {
    Vue.prototype.$pageAction = function (name, data) {
      if (name.startsWith('show')) {
        alert(`Action '${name}' (${data})`)
      }
      else {
        console.log(`Action '${name}'`, data);
      }
    }
  }
}
