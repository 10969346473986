import Container from '@inseego-digital/baseline-styles/components/Container.vue'
import { CtaButton, CtaLink, LargeCtaButton, SmallCtaButton, UnstyledCta } from '@inseego-digital/button-styles'
import {
  SanityImage,
  SanityImageBackground,
  SanityPictureSource,
  SanityVideoSource,
} from '@inseego-digital/sanity-image-components'

export default {
  install(Vue) {
    Vue.component('Container', Container)
    Vue.component('CtaButton', CtaButton)
    Vue.component('CtaLink', CtaLink)
    Vue.component('LargeCtaButton', LargeCtaButton)
    Vue.component('SmallCtaButton', SmallCtaButton)
    Vue.component('UnstyledCta', UnstyledCta)
    Vue.component('SanityImage', SanityImage)
    Vue.component('SanityImageBackground', SanityImageBackground)
    Vue.component('SanityPictureSource', SanityPictureSource)
    Vue.component('SanityVideoSource', SanityVideoSource)
  }
}
